import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: 'appUserActive, [appUserActive]'
})
export class UserActiveDirective {

    @Output() calltMethod = new EventEmitter();

    @HostListener('document:mousemove', ['$event']) 
    @HostListener('document:click', ['$event']) activeUser() {
        this.calltMethod.next('value');
    }

}