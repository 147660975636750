import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderWMessageComponent } from './loader-w-message/loader-w-message.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    LoaderWMessageComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  exports: [
    LoaderWMessageComponent
  ]
})
export class SharedComponentsModule { }
