import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { OrganizationComponent } from './pages/organization/organization.component';
import { AuthGuard } from './_guards/auth.guard';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./my-page/my-page.module').then(
        (module) => module.MyPageModule
      ),
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
