import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "rutFormat"
})

export class RutFormatPipe implements PipeTransform {

  transform(rut: string): string {
    const dv = rut[rut.length - 1];
    const rutWithoutDV = rut.slice(0, rut.length - 1).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const separador = !rut.includes('-')? '-': '';
     return `${rutWithoutDV}${separador}${dv}`;
  }
}