import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appOnlyText]'
})
export class OnlyTextDirective {

    regex = '^[a-zA-Za-úa-Ú0-9 ,.]';
    constructor(private el: ElementRef) { }

    @HostListener('keypress', ['$event']) onInputChange(event: any) {        
        return new RegExp(this.regex).test(event.key);
    }

    @HostListener('paste', ['$event']) blackPaste(event: ClipboardEvent) {
        this.validatorField(event);
    }

    private validatorField(event: ClipboardEvent): void {
        event.preventDefault();
        if (event.clipboardData) {
            const pasteData = event.clipboardData.getData('text/plain').replace(/[^a-zA-Z0-9 ,.]+/gi, '') || '';
            document.execCommand('insertHTML', false, pasteData);
        }
    }
}