import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appPhoneOnly]'
})
export class PhoneDirective {

    constructor(private el: ElementRef,
        private renderer: Renderer2) { }

    @HostListener('keyup') 
    checkKeyUp() {
        this.checkOnlyNumber(this.el.nativeElement.value);
    }

    private checkOnlyNumber(valor: any): void {
        this.renderer.setProperty(this.el.nativeElement, 'value', valor.replace(/[^0-9]/g, ''));
    }

}