import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { environment as env} from '../environments/environment';
import { BackendAuthService } from './shared/services/backend-auth.service';
import { CognitoService, IUserInfoCognito } from './shared/services/cognito.service';
import { InactivityService } from './shared/services/inactivity.service';
import { SessionService } from './shared/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { AppVersionService } from './shared/services/app-version.service';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { Store } from '@ngrx/store';
import { selectUserInfo } from './state/selectors/userInfo.selectors';
import { setUserInfo } from './state/actions/userInfo.actions';
import { setSelectedOrganization } from './state/actions/selectedOrganization.action';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-angular';

declare global {
  interface Window { dataLayer: any[]; }
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  private subscription: Subscription[] = [];
  public showContent: boolean = false;
  public ruta: string = '';
  organizations: string[] = [];
  hasUpdate: boolean = false;

  userInfo$ = this.store.select(selectUserInfo);

  constructor(
    private inactivityService: InactivityService,
    private backendAuthService: BackendAuthService,
    private _cognitoService: CognitoService,
    public router: Router,
    private _sessionService: SessionService,
    private activedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private _versionService: AppVersionService,
    private store: Store,
    ) {

    I18n.putVocabularies(translations);
    I18n.setLanguage('es')
    this.listenToAuthEvents();
    this.setStore();
    this.userActive();

    // console.log(this.router.url)

    this.subscription.push(this.backendAuthService.getToken().subscribe(resp => {
      this.showContent = resp ? true : false;
    }));

    this.subscription.push(this.router.events
    .pipe(filter(event => event instanceof NavigationEnd),
      map(() => this.activedRoute.snapshot),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
    )
    .subscribe((route: ActivatedRouteSnapshot) => {
      this.ruta = route.data.breadcrumb;
      const spanExtra: any = document.getElementById('span-menutoggle');
      if (spanExtra)
        spanExtra.textContent = '';
    }));

    // this.verifyToken();
    this.addUserIdDataLayer();

    this.userInfo$.subscribe((userInfo: IUserInfoCognito) => {
      this.redirectToPage(this.organizations);
    })
  }


  
  listenToAuthEvents() {
    Hub.listen('auth', async ({ payload } : any) => {
      switch (payload.event) {
        case 'signedIn':
          await this.setStore()
          // this.verifyToken();
          this.userActive();
          this.manageAppVersionStep1();
          this.router.navigate(['/landing-general'])
          break;
        
        case 'tokenRefresh':
          //Ponemos el nuevo token en el local storage para que lo consulte verifyToken
          const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
          if(idToken) {
            this._sessionService.setCurrentIdToken(idToken.toString());
          }
          break;
        }
    })
  }

  ngOnInit(): void {
    // check for platform update
    if(localStorage.getItem('shouldLogout')) {
      localStorage.removeItem('shouldLogout');
      this._cognitoService.logout();
    }
  }

  reloadSite(): void {
    // location.reload();
    window.location.href = window.location.href
  }

  async setStore() {
    //Ponemos el nuevo token en el local storage para que lo consulte verifyToken
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    if(idToken) {
      this._sessionService.setCurrentIdToken(idToken.toString());
    }
    const idTokenPayload = idToken?.payload!;
    const selectedOrganization = this._sessionService.getOrganization();

    const userInfo = <IUserInfoCognito>{
      organizations: idTokenPayload["cognito:groups"],
      username: idTokenPayload["email"],
      email: idTokenPayload["email"],
      userSub: idTokenPayload["sub"],
      given_name: idTokenPayload["given_name"],
      name: idTokenPayload["name"],
      phoneNumber: idTokenPayload["phone_number"]
    } 

    this.store.dispatch(setUserInfo({ userInfo }));
    this.store.dispatch(setSelectedOrganization({ selectedOrganization }));
  }

  /**
   * @description
   * Funcion que inicia o reinicia el observador de inactividad cada vez que el usuario mueve el
   * mouse o da click en cualquier parte de la pagina
   */
  async userActive(): Promise<void> {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    console.log(idToken)
    if (!this.inactivityService.modalIsOpen() && idToken) { //chequeo que el usuario este logeado y no est el modal abierto para empezar a contar
      this.inactivityService.initInactive();
    }
  }

  /**
   * @description
   * Verifica si el token es ha expirado cada N tiempo segun @param env.tokenObs, si lo esta cierra sesion
   */
  verifyToken(): void {
    const timer = setInterval(async () => {
      if (await this._cognitoService.tokenValidator()) { //Si devuelve true, se hace el logout
        clearInterval(timer);
        this.inactivityService.onStopWatching();
        this._cognitoService.logout();
      }
    }, env.tokenObs);
  }

  ngOnDestroy() {
    for (const item of this.subscription) {
      item.unsubscribe();
    }
  }

  changeNameUrl(url: string) {
    switch (url) {
      case 'buscarR':
        return 'buscar';
      case 'listar':
        return 'administración de usuarios';
      case 'usuarios':
        return 'configuraciones';
      default:
        return url? url.replace(/-/g, ' '): '';
    }
  }

  addUserIdDataLayer(): void {
    const dataLayer = window.dataLayer;
    const username = this._sessionService.getUserInfo()?.username
    if(dataLayer && username){
      dataLayer.push({
        'user_id': username
      });
    }
  }

  redirectToPage(organizations: string[]){
    if (!organizations) return;
    if (organizations.length == 0) {
      //redirect a OnBoarding
    } else {
      //redirect a landing
    }
  }

  manageAppVersionStep1() {
    // Algoritmo 1

    // 2. Chequear en la local storage si la variable version esta vacia o no
    let appVersion: string | null = this._sessionService.getAppVersion();
    
    // 3. esta vacia? entonces la voy a buscar al endpoint y la actualizamos en el local
    if(!appVersion || appVersion == "") {
      this._versionService.getLastDeployedVersion().subscribe((deployVersion: any) => {
        if(deployVersion.version) {
          this._sessionService.setAppVersion(deployVersion.version);
          this.manageAppVersionStep2();
        }
      })
    } else {
      // 4. no esta vacia? Fin
      this.manageAppVersionStep2();
    }
  }

  manageAppVersionStep2() {
    // Algoritmo 2
    // console.log("step2")
    interval(60 * 4 * 1000) // cada 4 min
    .pipe(
        mergeMap(() => this._versionService.getLastDeployedVersion())
    )
    .subscribe((deployVersion: any) => {
      let localAppVersion: string | null = this._sessionService.getAppVersion();
      if(!localAppVersion) {
          this.openLogoutDialog();
      }
      if(deployVersion.version && deployVersion.version !== localAppVersion) {
        this._sessionService.setAppVersion(deployVersion.version);
        this.openLogoutDialog();
      }
    })
  }

  openLogoutDialog() {
    // Espero 2 min para cerrar la sesión del usuario
    // Esto es para que no haga un logout justo en el mismo instante que se esta terminando de deployar y se cuelgue
    setTimeout(() => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
            title: 'Nueva versión',
            message: `Hay una nueva version del sitio, por favor cierre sesión y vuelva a ingresar`,
            textBoton: ['Ok'],
            textTimer: env.inactivity.countdown + 1,
            hideCancelmButtom: true
        }
      });

      dialogRef.afterClosed().subscribe(() => {
        localStorage.setItem('shouldLogout', 'true')
        this.reloadSite()
      });
    }, 4 * 60 * 1000)
  }

  logoutRefresh() {
    setTimeout(() => {
      location.reload()
    }, 1000);
    setTimeout(() => {
      this._cognitoService.logout()
    }, 1000);
  }
}

