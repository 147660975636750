import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class BackendAuthService {
  // private url: string = environment.backend.authorization;

  constructor(
    private _http: HttpClient,
    private _sessionService: SessionService) { }

  public getUserAuthToken(): string {
    return 'Bearer ' + localStorage.getItem('idToken') || '';
  }

  public setUserAuthToken(token: string) {
    localStorage.setItem('idToken', token);
  }

  public getUserAuthTokenOnly(): string {
    return localStorage.getItem('idToken') || '';
  }

  public getToken(): Observable<any> {
    const tokenObservable = new Observable(observer => {
      setTimeout(() => {
        observer.next(this._sessionService.getUserInfo());
      }, 100);
    });

    return tokenObservable;
  }

}
