import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-w-message',
  templateUrl: './loader-w-message.component.html',
  styleUrls: ['./loader-w-message.component.scss']
})
export class LoaderWMessageComponent {
  @Input() loadingMessage = ""
}
