<!-- <div id="layout-a" class="theme-blue"> -->
    <!-- menu toggle mobile -->
    <div [hidden]="router.url === '/login'" style="width: 100%; background-color: var(--primary-color);">
        <i class="text-white fa fa-bars nav-link p-2 p-lg-3 d-block d-xl-none menu-toggle me-2 me-lg-0 ml-2" style="font-size: 2em"></i>
    </div>

    <router-outlet></router-outlet>


<!-- </div> -->
