/**
 * Encrypt meodule for generate and encrypt strings
 */
export class Encrypt {
    /**
     * Generate a Random String
     * @returns {string}
     */
    public static getRandomString(): string {
        const randomItems = new Uint32Array(28);
        crypto.getRandomValues(randomItems);

        const binaryStringItems: string[] = [];
        randomItems.forEach((dec) => {
            binaryStringItems.push(`0${dec.toString(16).substr(-2)}`);
        })
        return binaryStringItems.reduce((acc, item) => `${acc}${item}`, '');
    }

    /**
     * Encrypt a String with SHA256
     * @param str An string to encode with SHA-256 algorithm
     * @returns {string}
     */
    public static async encryptStringWithSHA256(str: string): Promise<ArrayBuffer> {
        const PROTOCOL = 'SHA-256'
        const textEncoder = new TextEncoder();
        const encodedData = textEncoder.encode(str);
        return crypto.subtle.digest(PROTOCOL, encodedData);
    }

    /**
     * Convert Hash to Base64-URL
     * @param arrayBuffer 
     * @returns {string}
     */
    public static hashToBase64url(arrayBuffer: ArrayBuffer): string {
        const items = new Uint8Array(arrayBuffer)
        const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '')
        const decodedHash = btoa(stringifiedArrayHash)

        const base64URL = decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        return base64URL
    }
}