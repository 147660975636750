<div class="modal-content-confirm">
    <div class="modal-header">
        <h4>{{ confirmData.title || 'Confirm'}}</h4>
    </div>
    <div class="modal-body">
        <p>{{ confirmData.message || 'Are you sure?'}}</p>
    </div>

    <!-- ASI ESTABA ANTES -->
    <!-- <div class="modal-body" [ngClass]="dynamicClass? dynamicClass: ''">
        <p>{{message || 'Are you sure?'}}</p>
    </div> -->
    
    <div class="modal-footer">
        <p *ngIf="counter != 0 || confirmData.textTimer != undefined" class="custom-timer">
            {{counter}}s
        </p>
        <div *ngIf="!confirmData.hideCancelmButtom && confirmData.textBoton.length > 1">
            <button type="button" class="btn btn-outline-danger" (click)="close()">
                {{confirmData.textBoton.length > 0?  confirmData.textBoton[1]: 'Cancel'}}
            </button>
        </div>
        <button type="button" class="btn btn-primary" (click)="confirm()" *ngIf="!hideConfirmButtom">
            {{confirmData.textBoton.length > 0? confirmData.textBoton[0]: 'OK'}}
        </button>
    </div>
</div>