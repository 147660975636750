import { Pipe, PipeTransform } from "@angular/core";
import { property } from "lodash";

@Pipe({
  name: "sumTd",
  pure: false
})

export class SumTdPipe implements PipeTransform {
  transform(obj: any, property?: any, excluir?: string[]): number {

    let sum = 0;
    if (!property) {
      for (const element of Object.keys(obj)) {
        if (excluir && !excluir.includes(element)) {
          let sum2 = isNaN(Number(obj[element])) ? 0 : Number(obj[element]);
          sum = sum + sum2;
        }
      }
      return sum;
    } else {
      if (typeof property === 'string') {
        obj.forEach((element: any) => {
          let sum2 = isNaN(Number(element[property])) ? 0 : Number(element[property]);
          sum = sum + sum2;
        });
      } else {
        for (let i = 0; i < property; i++) {
          let sum2 = Number(obj[i]);
          sum = sum + sum2;
        }
      }
      return sum;
    }
  }
}
