import { Component, OnInit } from '@angular/core';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { IUserInfoCognito } from '../shared/services/cognito.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
  ) { 
  }

  async ngOnInit(): Promise<void> {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      if(idToken) this.router.navigate([''])
    } catch (err) {
      console.log(err);
    }
  }


}
