import { Component, Input, AfterViewInit, Inject } from '@angular/core';
// import { SimpleModalComponent } from "ngx-simple-modal";
import { take, map } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmModel {
  title: string;
  message: string;
  textBoton: string[];
  textTimer: number;
  dynamicClass: string;
  hideConfirmButtom: boolean;
  hideCancelmButtom: boolean;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements  AfterViewInit {
  @Input() title: string = "";
  @Input() message: string = "";
  @Input() textBoton: string[] = [];
  @Input() textTimer: number = 0;
  @Input() dynamicClass: string = '';
  @Input() hideConfirmButtom: boolean = false;
  @Input() hideCancelmButtom: boolean = false;

  confirmData: ConfirmModel = {
    title: "",
    message: "",
    textBoton: [],
    textTimer: 0,
    dynamicClass: "",
    hideConfirmButtom: false,
    hideCancelmButtom: false,
  };

  // timer: Observable<number>;
  public counter$: Observable<number> = new Observable;

  public counter = 0;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel,) {
      this.confirmData = data;

      console.log(this.confirmData)
      timer(0, 1000).pipe(
        // Take values until the specified duration is reached
        take(this.confirmData.textTimer + 1),
        // Subtract the emitted values from the duration to get the countdown
        map((value) => this.confirmData.textTimer - value)
      ).subscribe((counter) => {
        this.counter = counter;

        // Close the dialog when the countdown reaches zero
        if (counter === 0) {
          this.close();
        }
      });
  }

  //confirm
  confirm() {
    // we set modal result as true on click on confirm button,
    // then we can get modal result from caller code
    // this.result = true;
    // this.close();
    this.dialogRef.close(true);
  }

  //cancel
  close() {
    this.dialogRef.close(false);
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.confirmData.textTimer > 0) {
      this.counter$ = await timer(0, 1000).pipe(
        take(this.textTimer),
        map(() => {
          return --this.textTimer
        }));
    }
  }

}
