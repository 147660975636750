/**
 * Custom module for send HTTP requests
 */
export class Request {
    public static request<T>(url: string, options = {}): Promise<T> {
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json() as Promise<T>
            })
    }
}