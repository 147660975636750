/**
 * JWTToken module for manage JWT Token
 */
export class JWTToken {
    /**
     * Convert Payload from Base64-URL to JSON
     * @param payload 
     * @returns {string}
     */
    public static decodePayload(payload: string) {
        const cleanedPayload = payload.replace(/-/g, '+').replace(/_/g, '/');
        const decodedPayload = atob(cleanedPayload)
        const uriEncodedPayload = Array.from(decodedPayload).reduce((acc, char) => {
            const uriEncodedChar = ('00' + char.charCodeAt(0).toString(16)).slice(-2)
            return `${acc}%${uriEncodedChar}`
        }, '')
        const jsonPayload = decodeURIComponent(uriEncodedPayload);

        return JSON.parse(jsonPayload)
    }

    /**
     * Parse JWT Payload
     * @param token 
     * @returns {string}
     */
    public static parseJWTPayload(token: string) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [header, payload, signature] = token.split('.');
        return JWTToken.decodePayload(payload)
    }

    /**
     * Parse JWT Header
     * @param token 
     * @returns {string}
     */
    public static parseJWTHeader(token: string) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [header, payload, signature] = token.split('.');
        return JWTToken.decodePayload(header);
    }
}