import { createReducer, on } from '@ngrx/store';
import { setUserInfo } from '../actions/userInfo.actions'
import { IUserInfoCognito } from 'src/app/shared/services/cognito.service';
import { ESessionKeys } from 'src/app/shared/services/session.service';

export const initialState: IUserInfoCognito = {
    organizations: [],
    username: '',
    email: '',
    userSub: '',
    given_name: '',
    phoneNumber: '',
    name: ''
}

export const userInfoReducer = createReducer(
    initialState,
    on(setUserInfo, (_state, { userInfo }) => {
        // localStorage.setItem(ESessionKeys.USER_INFO, JSON.stringify(userInfo));//persistencia despues de un f5
        return userInfo
    })
);

