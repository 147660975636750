import { createReducer, on } from '@ngrx/store';
import { ESessionKeys } from 'src/app/shared/services/session.service';
import { setSelectedOrganization } from '../actions/selectedOrganization.action';

export const initialState: string = "";

export const selectedOrganizationReducer = createReducer(
    initialState,
    on(setSelectedOrganization, (_state, { selectedOrganization }) => {
        localStorage.setItem(ESessionKeys.ORGANIZATION, selectedOrganization);//persistencia despues de un f5
        return selectedOrganization
    })
);

