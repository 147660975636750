import { Pipe, PipeTransform } from "@angular/core";
import { orderBy } from 'lodash';

@Pipe({
 name: "orderBy"
})

export class OrderByPipe implements PipeTransform {
 transform(array: any, sortBy: string, order?: string, nivel?: string): any[] {
 const sortOrder = order === 'desc'? order : 'asc';
 

 
  return orderBy(array, [sortBy], [sortOrder]);
  }
}