import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppVersionService {

  constructor(private _http: HttpClient) { }

  getLastDeployedVersion(): Observable<any> {
    return this._http.get(environment.appVersionUrl);
  }
}
